<template>
  <ItemList v-if="sources" :sources="sources" :showCategoryName="!route.params.slug" />
  <!-- {{ sources.length }} DO NOT REMOVE. THIS SERVES PURPOSE-->
  <div hidden>{{ sources.map(x => x.slug).join("-") }}</div>
</template>

<script async setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { computedAsync, } from '@vueuse/core'
import useLocalSetting from "@/composables/useLocalSetting"
import useData from "@/composables/useData"

const title = ref('');

const route = useRoute()
const router = useRouter();


const {
  sources: allSources,
  categories: allCategories,
} = await useData()
const category = computed(() => {
  if (route.params.slug) {
    const slug = route.params.slug.toLowerCase();
    const category = allCategories.value.find(x => x.slug === slug)
    if (category) {
      return category
    } else {
      return router.replace({ params: { slug: '' } })
    }
  } else {
    return null;
  }
})

const pageSources = computed(() => {
  if (category.value) {
    return allSources.value.filter(s => category.value.sources.some(cs => s.slug === cs))
  } else {
    return allSources.value;
  }
})

const hiddenSources = useLocalSetting('hiddenSources', 1, {});

const sources = computed(() => pageSources.value.filter(s =>
    !hiddenSources.value[s.slug]
  )
)

</script>
